var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    [
      _c(
        "CCardHeader",
        [
          _c("CIcon", { attrs: { name: "cil-notes" } }),
          _c(
            "h5",
            { staticClass: "d-inline ml-2" },
            [
              _vm._v(" " + _vm._s(this.$route.meta.label) + " "),
              _vm.isEdit
                ? [
                    _vm._v(" : "),
                    _c(
                      "h3",
                      { staticClass: "d-inline" },
                      [
                        _c("CBadge", { attrs: { color: "secondary" } }, [
                          _vm._v(_vm._s(this.itemName)),
                        ]),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "card-header-actions" },
            [
              this.isEdit
                ? _c(
                    "CButton",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        to: {
                          name: "View Restaurant",
                          params: { id: _vm.itemId },
                        },
                        color: "info",
                        disabled: _vm.submitted,
                      },
                    },
                    [
                      _c("CIcon", { attrs: { name: "cil-external-link" } }),
                      _vm._v(" View Restaurant "),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "CButton",
                { attrs: { color: "dark", to: _vm.prevRoute } },
                [
                  _c("CIcon", {
                    staticClass: "align-bottom",
                    attrs: { name: "cil-arrow-thick-left" },
                  }),
                  _vm._v(" Back "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CCardBody",
        [
          _c(
            "div",
            {
              staticClass: "d-flex flex-column flex-md-row flex-gap-2",
              class: { "mb-4": _vm.form.email && !_vm.form.upload_contract },
            },
            [
              _c(
                "div",
                { staticClass: "flex-grow-1" },
                [
                  _vm.form.email && !_vm.form.upload_contract
                    ? _c("UnsignedContract", {
                        attrs: {
                          id: Number(_vm.itemId),
                          email: _vm.form.email,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                [
                  _vm.hasdeliveryService
                    ? _c(
                        "CAlert",
                        {
                          staticClass:
                            "d-flex flex-gap-2 align-items-center mb-0 h-100",
                          attrs: { color: "success" },
                        },
                        [
                          _c("CIcon", {
                            attrs: { name: "cil-truck", size: "2xl" },
                          }),
                          _c("div", [
                            _c("strong", [_vm._v("Delivery Service enabled.")]),
                            !_vm.hubsCount
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-gap-2 align-items-center text-danger font-weight-bold flash-it",
                                  },
                                  [
                                    _vm._v(
                                      " ⚠ Add the postcode (" +
                                        _vm._s(_vm.form.restaurant_postcode) +
                                        ") to the scope of a hub. "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c("alert-section", {
            attrs: {
              successAlertMessage: _vm.successAlertMessage,
              dismissSecs: _vm.dismissSecs,
              dismissSuccessAlert: _vm.dismissSuccessAlert,
              errorAlertMessage: _vm.errorAlertMessage,
              showErrorAlert: _vm.showErrorAlert,
            },
          }),
          !_vm.isValid
            ? _c("form-summary", {
                staticClass: "form-errors alert alert-danger",
                attrs: { validator: _vm.$v.form, attributes: _vm.attributes },
              })
            : _vm._e(),
          _c(
            "CForm",
            { staticClass: "restaurant-form" },
            [
              _c(
                "CTabs",
                {
                  staticClass: "tab-menu",
                  attrs: {
                    "active-tab": _vm.activeTab,
                    variant: "pills",
                    vertical: {
                      navs: "col-xl-2 col-md-3",
                      content: "col-xl-10 col-md-9",
                    },
                  },
                  on: {
                    "update:activeTab": function ($event) {
                      _vm.activeTab = $event
                    },
                    "update:active-tab": function ($event) {
                      _vm.activeTab = $event
                    },
                  },
                },
                [
                  _c(
                    "CTab",
                    { attrs: { active: "" } },
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        [
                          _c("CIcon", { attrs: { name: "cil-task" } }),
                          _vm._v(" General Information "),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c(
                          "div",
                          {
                            staticClass: "mb-3 d-flex flex-column flex-md-row",
                          },
                          [
                            _c("CSelect", {
                              staticClass: "w-100 mr-2",
                              attrs: {
                                label: "Country:",
                                placeholder: "Please select..",
                                lazy: false,
                                options: _vm.authCountries,
                                value: _vm.$v.form.countryId.$model,
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.$v.form.countryId,
                                    "$model",
                                    $event
                                  )
                                },
                              },
                            }),
                            _c("CSelect", {
                              staticClass: "w-100 mr-2",
                              attrs: {
                                label: "Language:",
                                placeholder: "Please select..",
                                lazy: false,
                                value: _vm.$v.form.lang.$model,
                                options: _vm.allLanguages,
                                isValid: _vm.checkIfValid("lang"),
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.$v.form.lang,
                                    "$model",
                                    $event
                                  )
                                },
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "form-group w-100" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "d-block",
                                    staticStyle: { "font-weight": "500" },
                                  },
                                  [_vm._v("Exclusive:")]
                                ),
                                _c("CSwitch", {
                                  staticClass: "mt-020",
                                  attrs: {
                                    checked: _vm.$v.form.exclusive.$model,
                                    labelOn: "YES",
                                    labelOff: "NO",
                                    color: "success",
                                    shape: "pill",
                                    size: "lg",
                                    lazy: false,
                                  },
                                  on: {
                                    "update:checked": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.exclusive,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mb-3 d-flex flex-column flex-md-row",
                          },
                          [
                            _c("CInput", {
                              staticClass: "w-100 mr-2",
                              attrs: {
                                label: "Name of Restaurant:",
                                placeholder: "Name of Restaurant",
                                lazy: false,
                                value: _vm.$v.form.restaurant_name.$model,
                                isValid: _vm.checkIfValid("restaurant_name"),
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.$v.form.restaurant_name,
                                    "$model",
                                    $event
                                  )
                                },
                              },
                            }),
                            _c("CInput", {
                              staticClass: "w-100",
                              attrs: {
                                label: "Slug:",
                                placeholder: "Slug",
                                lazy: false,
                                value: _vm.$v.form.restaurant_slug.$model,
                                isValid: _vm.checkIfValid("restaurant_slug"),
                                disabled: _vm.invoiceCount > 0,
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.$v.form.restaurant_slug,
                                    "$model",
                                    $event
                                  )
                                },
                              },
                              model: {
                                value: _vm.restaurantSlug,
                                callback: function ($$v) {
                                  _vm.restaurantSlug = $$v
                                },
                                expression: "restaurantSlug",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "section",
                          { staticClass: "address mb-3" },
                          [
                            _c("CInput", {
                              staticClass: "w-100",
                              attrs: {
                                id: "verify_address",
                                value: _vm.verified_address,
                                label: "Verify Address From Google:",
                                placeholder: "Enter a location",
                                lazy: false,
                              },
                              on: {
                                "update:value": function ($event) {
                                  _vm.verified_address = $event
                                },
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "prepend",
                                  fn: function () {
                                    return [
                                      _c(
                                        "CButton",
                                        { attrs: { color: "info" } },
                                        [
                                          _c("CIcon", {
                                            attrs: { name: "cil-location-pin" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column flex-md-row" },
                              [
                                _c("CTextarea", {
                                  staticClass: "w-100",
                                  attrs: {
                                    rows: "2",
                                    label: "Address:",
                                    placeholder: "Street name and house number",
                                    lazy: false,
                                    value:
                                      _vm.$v.form.restaurant_address.$model,
                                    isValid:
                                      _vm.checkIfValid("restaurant_address"),
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.restaurant_address,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column flex-md-row" },
                              [
                                _c("CInput", {
                                  staticClass: "w-100 mr-2",
                                  attrs: {
                                    id: "PostalCodeNum",
                                    type: "number",
                                    label: "Postal Code:",
                                    placeholder: "Zip or Postal Code",
                                    lazy: false,
                                    value:
                                      _vm.$v.form.restaurant_postcode.$model,
                                    isValid: _vm.checkIfValid(
                                      "restaurant_postcode"
                                    ),
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.restaurant_postcode,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                                _c("CInput", {
                                  staticClass: "w-100 mr-2",
                                  attrs: {
                                    label: "City:",
                                    placeholder: "City",
                                    lazy: false,
                                    value: _vm.$v.form.restaurant_city.$model,
                                    isValid:
                                      _vm.checkIfValid("restaurant_city"),
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.restaurant_city,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                                _c("CInput", {
                                  staticClass: "w-100 mr-2",
                                  attrs: {
                                    readonly: "",
                                    label: "Latitude:",
                                    placeholder: "Latitude",
                                    lazy: false,
                                    value: _vm.$v.form.resto_lat.$model,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.resto_lat,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "prepend",
                                      fn: function () {
                                        return [
                                          _c(
                                            "CLoadingButton",
                                            {
                                              attrs: {
                                                loading: _vm.loading,
                                                color:
                                                  _vm.isCoordinateUptodate &&
                                                  !!_vm.form.resto_lat &&
                                                  !!_vm.form.resto_lat
                                                    ? "dark"
                                                    : "warning",
                                                disabled:
                                                  _vm.isCoordinateUptodate &&
                                                  !!_vm.form.resto_lat &&
                                                  !!_vm.form.resto_lat,
                                                onChange:
                                                  _vm.getCoordinateFromAddress,
                                              },
                                            },
                                            [
                                              _c("CIcon", {
                                                attrs: { name: "cil-sync" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _c("CInput", {
                                  staticClass: "w-100",
                                  attrs: {
                                    readonly: "",
                                    label: "Longitude:",
                                    placeholder: "Longitude",
                                    lazy: false,
                                    value: _vm.$v.form.resto_lng.$model,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.resto_lng,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mb-3 d-flex flex-column flex-md-row",
                          },
                          [
                            _c("CInput", {
                              staticClass: "w-100 mr-2",
                              attrs: {
                                label: "Phone Number:",
                                placeholder: "Restaurant phone number",
                                lazy: false,
                                value: _vm.$v.form.restaurant_tel.$model,
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.$v.form.restaurant_tel,
                                    "$model",
                                    $event
                                  )
                                },
                              },
                            }),
                            _c("CInput", {
                              staticClass: "w-100",
                              attrs: {
                                label: "Mobile Number:",
                                placeholder: "Restaurant mobile number",
                                lazy: false,
                                value: _vm.$v.form.restaurant_gsm.$model,
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.$v.form.restaurant_gsm,
                                    "$model",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mb-3 d-flex flex-column flex-md-row",
                          },
                          [
                            _c("CInput", {
                              staticClass: "w-100 mr-2",
                              attrs: {
                                label: "Website:",
                                placeholder: "Website",
                                lazy: false,
                                value: _vm.$v.form.website.$model,
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.$v.form.website,
                                    "$model",
                                    $event
                                  )
                                },
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "CLink",
                                        {
                                          staticClass: "btn btn-info",
                                          attrs: {
                                            role: "button",
                                            target: "_blank",
                                            href: _vm._f("addUriScheme")(
                                              _vm.$v.form.website.$model
                                            ),
                                          },
                                        },
                                        [
                                          _c("CIcon", {
                                            attrs: {
                                              name: "cil-external-link",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                            _c("CInput", {
                              staticClass: "w-100",
                              attrs: {
                                label: `Website ${_vm.appTitle}:`,
                                placeholder: `Website ${_vm.appTitle}`,
                                lazy: false,
                                value: _vm.$v.form.website_etenonline_be.$model,
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.$v.form.website_etenonline_be,
                                    "$model",
                                    $event
                                  )
                                },
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "CLink",
                                        {
                                          staticClass: "btn btn-info",
                                          attrs: {
                                            role: "button",
                                            target: "_blank",
                                            href: _vm._f("addUriScheme")(
                                              _vm.$v.form.website_etenonline_be
                                                .$model
                                            ),
                                          },
                                        },
                                        [
                                          _c("CIcon", {
                                            attrs: {
                                              name: "cil-external-link",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mb-3 d-flex flex-column flex-md-row",
                          },
                          [
                            _c("CInput", {
                              staticClass: "w-100 mr-2 email",
                              attrs: {
                                label: "Email:",
                                placeholder: "Email",
                                lazy: false,
                                value: _vm.$v.form.email.$model,
                                isValid: _vm.checkIfValid("email"),
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.$v.form.email,
                                    "$model",
                                    $event
                                  )
                                },
                              },
                            }),
                            _c("CInput", {
                              staticClass: "w-100",
                              attrs: {
                                label: "Facebook Page:",
                                placeholder: "Facebook page",
                                lazy: false,
                                value: _vm.$v.form.facebookpage.$model,
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.$v.form.facebookpage,
                                    "$model",
                                    $event
                                  )
                                },
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "CLink",
                                        {
                                          staticClass: "btn btn-info",
                                          attrs: {
                                            role: "button",
                                            target: "_blank",
                                            href: _vm.$v.form.facebookpage
                                              .$model
                                              ? "https://www.facebook.com/" +
                                                _vm.$v.form.facebookpage.$model
                                              : null,
                                          },
                                        },
                                        [
                                          _c("CIcon", {
                                            attrs: {
                                              name: "cil-external-link",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mb-3 d-flex flex-column flex-md-row",
                          },
                          [
                            _c("CInput", {
                              staticClass: "w-100 mr-2",
                              attrs: {
                                label: "Motto:",
                                placeholder: "Motto",
                                lazy: false,
                                value: _vm.$v.form.slogan.$model,
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.$v.form.slogan,
                                    "$model",
                                    $event
                                  )
                                },
                              },
                            }),
                            _c("CInput", {
                              staticClass: "w-100",
                              attrs: {
                                label: "Public note above menu:",
                                placeholder: "Public note above menu",
                                lazy: false,
                                value: _vm.$v.form.note_public.$model,
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.$v.form.note_public,
                                    "$model",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex flex-column flex-md-row" },
                          [
                            _c(
                              "div",
                              { staticClass: "form-group w-100 mb-0" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "d-block",
                                    staticStyle: { "font-weight": "500" },
                                  },
                                  [_vm._v("More information for the website:")]
                                ),
                                _c("quill-editor", {
                                  staticStyle: { "min-height": "200px" },
                                  attrs: {
                                    options: {
                                      placeholder:
                                        "More information for the website",
                                    },
                                    disabled: _vm.quill_disabled,
                                  },
                                  model: {
                                    value:
                                      _vm.$v.form.restaurant_description.$model,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.$v.form.restaurant_description,
                                        "$model",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "$v.form.restaurant_description.$model",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "CTab",
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        [
                          _c("CIcon", { attrs: { name: "cil-text" } }),
                          _vm._v(" Types "),
                        ],
                        1
                      ),
                      _c("LinkedTypes", {
                        attrs: {
                          restaurantId: _vm.itemId,
                          linkedTypes: _vm.form.restaurant_types,
                        },
                        on: {
                          "update:linkedTypes": function ($event) {
                            return _vm.$set(
                              _vm.form,
                              "restaurant_types",
                              $event
                            )
                          },
                          "update:linked-types": function ($event) {
                            return _vm.$set(
                              _vm.form,
                              "restaurant_types",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    2
                  ),
                  _c(
                    "CTab",
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        [
                          _c("CIcon", { attrs: { name: "cil-spa" } }),
                          _vm._v(" Logo "),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column" },
                        [
                          _c(
                            "CMedia",
                            {
                              attrs: {
                                "aside-image-props": {
                                  src: this.previewImage,
                                  shape: "rounded",
                                  width: "100%",
                                  height: "120px",
                                },
                              },
                            },
                            [
                              _c(
                                "p",
                                { staticClass: "mb-2 font-weight-bold" },
                                [_vm._v("Choose a logo file:")]
                              ),
                              _c("CInput", {
                                staticClass: "mb-0",
                                attrs: {
                                  value: _vm.$v.form.restaurant_logo.$model,
                                  type: "hidden",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.$v.form.restaurant_logo,
                                      "$model",
                                      $event
                                    )
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "btn btn-outline-info mb-0",
                                  attrs: { for: "img" },
                                },
                                [
                                  _c("CIcon", { attrs: { name: "cil-file" } }),
                                  _vm._v(" Browse "),
                                ],
                                1
                              ),
                              _c("CInputFile", {
                                class: !_vm.checkIfValid("restaurant_logo")
                                  ? "is-invalid"
                                  : "",
                                staticStyle: { display: "none" },
                                attrs: { id: "img" },
                                on: { change: _vm.onFileChanged },
                              }),
                              !_vm.checkIfValid("restaurant_logo")
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      !_vm.$v.form.restaurant_logo.required
                                        ? _c("div", { staticClass: "error" }, [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-size": "1.2rem",
                                                },
                                              },
                                              [_vm._v("⚠️")]
                                            ),
                                            _vm._v(
                                              " Please select a logo for the restaurant! "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.previewImage
                            ? _c(
                                "CLink",
                                {
                                  staticClass: "small mt-2 text-danger",
                                  on: {
                                    click: () => {
                                      this.selectedImage = this.previewImage =
                                        null
                                      this.form.restaurant_logo = ""
                                    },
                                  },
                                },
                                [
                                  _c("CIcon", { attrs: { name: "cil-trash" } }),
                                  _vm._v(" Remove Logo "),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "CTab",
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        [
                          _c("CIcon", { attrs: { name: "cil-clock" } }),
                          _vm._v(" Opening Time "),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 col-sm-4" },
                          [
                            _c(
                              "CButton",
                              {
                                staticClass: "px-0",
                                attrs: { color: "link" },
                                on: { click: _vm.openingTimesCopyTo },
                              },
                              [
                                _vm.copySucceeded.openingTimesCopyTo
                                  ? _c(
                                      "span",
                                      [
                                        _c("CIcon", {
                                          staticClass: "mr-1",
                                          attrs: { name: "cil-check-alt" },
                                        }),
                                        _vm._v("Applied!"),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "span",
                                      [
                                        _c("CIcon", {
                                          staticClass: "mr-1",
                                          attrs: { name: "cil-copy" },
                                        }),
                                        _vm._v("Apply Monday to all days"),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-3 d-none d-sm-block px-sm-0" },
                          [
                            _c("h5", { staticClass: "mb-0" }, [
                              _vm._v("Open from"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-3 d-none d-sm-block" },
                          [
                            _c("h5", { staticClass: "mb-0" }, [
                              _vm._v("Open till"),
                            ]),
                          ]
                        ),
                      ]),
                      _c("restaurant-times", {
                        attrs: {
                          day: "Monday",
                          time: _vm.$v.form.open_monday.$model,
                        },
                        on: {
                          isValid: (value) =>
                            (_vm.isValidOpeningTime.mo = value),
                          "update:time": function ($event) {
                            return _vm.$set(
                              _vm.$v.form.open_monday,
                              "$model",
                              $event
                            )
                          },
                        },
                      }),
                      _c("restaurant-times", {
                        attrs: {
                          day: "Tuesday",
                          time: _vm.$v.form.open_tuesday.$model,
                        },
                        on: {
                          isValid: (value) =>
                            (_vm.isValidOpeningTime.tu = value),
                          "update:time": function ($event) {
                            return _vm.$set(
                              _vm.$v.form.open_tuesday,
                              "$model",
                              $event
                            )
                          },
                        },
                      }),
                      _c("restaurant-times", {
                        attrs: {
                          day: "Wednesday",
                          time: _vm.$v.form.open_wednesday.$model,
                        },
                        on: {
                          isValid: (value) =>
                            (_vm.isValidOpeningTime.we = value),
                          "update:time": function ($event) {
                            return _vm.$set(
                              _vm.$v.form.open_wednesday,
                              "$model",
                              $event
                            )
                          },
                        },
                      }),
                      _c("restaurant-times", {
                        attrs: {
                          day: "Thursday",
                          time: _vm.$v.form.open_thursday.$model,
                        },
                        on: {
                          isValid: (value) =>
                            (_vm.isValidOpeningTime.th = value),
                          "update:time": function ($event) {
                            return _vm.$set(
                              _vm.$v.form.open_thursday,
                              "$model",
                              $event
                            )
                          },
                        },
                      }),
                      _c("restaurant-times", {
                        attrs: {
                          day: "Friday",
                          time: _vm.$v.form.open_friday.$model,
                        },
                        on: {
                          isValid: (value) =>
                            (_vm.isValidOpeningTime.fr = value),
                          "update:time": function ($event) {
                            return _vm.$set(
                              _vm.$v.form.open_friday,
                              "$model",
                              $event
                            )
                          },
                        },
                      }),
                      _c("restaurant-times", {
                        attrs: {
                          day: "Saturday",
                          time: _vm.$v.form.open_saturday.$model,
                        },
                        on: {
                          isValid: (value) =>
                            (_vm.isValidOpeningTime.sa = value),
                          "update:time": function ($event) {
                            return _vm.$set(
                              _vm.$v.form.open_saturday,
                              "$model",
                              $event
                            )
                          },
                        },
                      }),
                      _c("restaurant-times", {
                        attrs: {
                          day: "Sunday",
                          time: _vm.$v.form.open_sunday.$model,
                        },
                        on: {
                          isValid: (value) =>
                            (_vm.isValidOpeningTime.su = value),
                          "update:time": function ($event) {
                            return _vm.$set(
                              _vm.$v.form.open_sunday,
                              "$model",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    2
                  ),
                  _c(
                    "CTab",
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        [
                          _c("CIcon", { attrs: { name: "cil-bike" } }),
                          _vm._v(" Delivery Time "),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "row mb-2" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm" },
                          [
                            _c(
                              "CLink",
                              {
                                staticClass: "d-block mb-3",
                                attrs: { color: "info" },
                                on: {
                                  click: _vm.copyFromOpeningTimeToDeliveryTime,
                                },
                              },
                              [
                                _vm.copySucceeded.openingTime
                                  ? _c(
                                      "span",
                                      [
                                        _c("CIcon", {
                                          attrs: { name: "cil-check-alt" },
                                        }),
                                        _vm._v(" Copied!"),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "span",
                                      [
                                        _c("CIcon", {
                                          staticClass: "mr-1",
                                          attrs: { name: "cil-clone" },
                                        }),
                                        _vm._v("Copy From Opening Time"),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 col-sm-4" },
                          [
                            _c(
                              "CButton",
                              {
                                staticClass: "px-0",
                                attrs: { color: "link" },
                                on: { click: _vm.deliveryTimesCopyTo },
                              },
                              [
                                _vm.copySucceeded.deliveryTimesCopyTo
                                  ? _c(
                                      "span",
                                      [
                                        _c("CIcon", {
                                          staticClass: "mr-1",
                                          attrs: { name: "cil-check-alt" },
                                        }),
                                        _vm._v("Applied!"),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "span",
                                      [
                                        _c("CIcon", {
                                          staticClass: "mr-1",
                                          attrs: { name: "cil-copy" },
                                        }),
                                        _vm._v("Apply Monday to all days"),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-3 d-none d-sm-block px-sm-0" },
                          [
                            _c("h5", { staticClass: "mb-0" }, [
                              _vm._v("Open from"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-3 d-none d-sm-block" },
                          [
                            _c("h5", { staticClass: "mb-0" }, [
                              _vm._v("Open till"),
                            ]),
                          ]
                        ),
                      ]),
                      _c("restaurant-times", {
                        attrs: {
                          day: "Monday",
                          time: _vm.$v.form.deliver_monday.$model,
                        },
                        on: {
                          isValid: (value) =>
                            (_vm.isValidDeliveryTime.mo = value),
                          "update:time": function ($event) {
                            return _vm.$set(
                              _vm.$v.form.deliver_monday,
                              "$model",
                              $event
                            )
                          },
                        },
                      }),
                      _c("restaurant-times", {
                        attrs: {
                          day: "Tuesday",
                          time: _vm.$v.form.deliver_tuesday.$model,
                        },
                        on: {
                          isValid: (value) =>
                            (_vm.isValidDeliveryTime.tu = value),
                          "update:time": function ($event) {
                            return _vm.$set(
                              _vm.$v.form.deliver_tuesday,
                              "$model",
                              $event
                            )
                          },
                        },
                      }),
                      _c("restaurant-times", {
                        attrs: {
                          day: "Wednesday",
                          time: _vm.$v.form.deliver_wednesday.$model,
                        },
                        on: {
                          isValid: (value) =>
                            (_vm.isValidDeliveryTime.we = value),
                          "update:time": function ($event) {
                            return _vm.$set(
                              _vm.$v.form.deliver_wednesday,
                              "$model",
                              $event
                            )
                          },
                        },
                      }),
                      _c("restaurant-times", {
                        attrs: {
                          day: "Thursday",
                          time: _vm.$v.form.deliver_thursday.$model,
                        },
                        on: {
                          isValid: (value) =>
                            (_vm.isValidDeliveryTime.th = value),
                          "update:time": function ($event) {
                            return _vm.$set(
                              _vm.$v.form.deliver_thursday,
                              "$model",
                              $event
                            )
                          },
                        },
                      }),
                      _c("restaurant-times", {
                        attrs: {
                          day: "Friday",
                          time: _vm.$v.form.deliver_friday.$model,
                        },
                        on: {
                          isValid: (value) =>
                            (_vm.isValidDeliveryTime.fr = value),
                          "update:time": function ($event) {
                            return _vm.$set(
                              _vm.$v.form.deliver_friday,
                              "$model",
                              $event
                            )
                          },
                        },
                      }),
                      _c("restaurant-times", {
                        attrs: {
                          day: "Saturday",
                          time: _vm.$v.form.deliver_saturday.$model,
                        },
                        on: {
                          isValid: (value) =>
                            (_vm.isValidDeliveryTime.sa = value),
                          "update:time": function ($event) {
                            return _vm.$set(
                              _vm.$v.form.deliver_saturday,
                              "$model",
                              $event
                            )
                          },
                        },
                      }),
                      _c("restaurant-times", {
                        attrs: {
                          day: "Sunday",
                          time: _vm.$v.form.deliver_sunday.$model,
                        },
                        on: {
                          isValid: (value) =>
                            (_vm.isValidDeliveryTime.su = value),
                          "update:time": function ($event) {
                            return _vm.$set(
                              _vm.$v.form.deliver_sunday,
                              "$model",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    2
                  ),
                  _c(
                    "CTab",
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        [
                          _c("CIcon", { attrs: { name: "cil-location-pin" } }),
                          _vm._v(" ZIP Codes "),
                        ],
                        1
                      ),
                      _c(
                        "CLink",
                        {
                          staticClass: "mb-2 d-block",
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.mapModal = true
                            },
                          },
                        },
                        [
                          _c("CIcon", { attrs: { name: "cil-location-pin" } }),
                          _vm._v(" Show on Map "),
                        ],
                        1
                      ),
                      _c("zip-codes", {
                        ref: "resZipCodes",
                        attrs: {
                          active: _vm.activeTab == 5,
                          zip_codes: _vm.form.zip_codes,
                          restaurant_zip_code: _vm.form.restaurant_postcode,
                          restaurant_country: _vm.form.countryId,
                          restaurant_lat: _vm.form.resto_lat,
                          restaurant_lng: _vm.form.resto_lng,
                        },
                        on: {
                          "update:zip_codes": function ($event) {
                            return _vm.$set(_vm.form, "zip_codes", $event)
                          },
                        },
                      }),
                    ],
                    2
                  ),
                  _c(
                    "CTab",
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        [
                          _c("CIcon", { attrs: { name: "cil-institution" } }),
                          _vm._v(" Company Info "),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-0 d-flex flex-column flex-sm-row" },
                        [
                          _c("CInput", {
                            staticClass: "w-100 mr-2",
                            attrs: {
                              label: "VAT (BTW) Number:",
                              placeholder: "VAT (BTW) Number",
                              lazy: false,
                              value: _vm.$v.form.btw_nummer.$model,
                              disabled: _vm.invoiceCount > 0,
                              isValid: _vm.checkIfValid("btw_nummer"),
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.$v.form.btw_nummer,
                                  "$model",
                                  $event
                                )
                              },
                              focusout: _vm.fixVat,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function () {
                                  return [
                                    _c(
                                      "CButton",
                                      {
                                        attrs: {
                                          color: "info",
                                          disabled:
                                            _vm.checkSpinner ||
                                            !_vm.$v.form.btw_nummer.$model ||
                                            !_vm.isValidVat(
                                              _vm.form.btw_nummer
                                            ),
                                        },
                                        on: { click: _vm.getCompanyInfo },
                                      },
                                      [
                                        _vm.checkSpinner
                                          ? _c("CSpinner", {
                                              attrs: { size: "sm" },
                                            })
                                          : _vm._e(),
                                        _vm._v(" Get Company Info "),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                          _c(
                            "div",
                            { staticClass: "form-group w-100" },
                            [
                              _vm.form.btw_nummer &&
                              _vm.isValidVat(_vm.form.btw_nummer)
                                ? [
                                    _c(
                                      "label",
                                      { staticClass: "d-none d-sm-block" },
                                      [_vm._v(" ")]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn p-1",
                                        attrs: {
                                          href: `https://kbopub.economie.fgov.be/kbopub/toonondernemingps.html?lang=NL&ondernemingsnummer=${_vm.form.btw_nummer}`,
                                          target: "blank",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/images/logo-kbo.jpg"),
                                            height: "28",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "CAlert",
                            {
                              attrs: {
                                show: _vm.allRestaurantByVAT.length > 0,
                                color: "info",
                              },
                            },
                            [
                              _c("CIcon", {
                                staticClass: "mr-2",
                                attrs: { height: 24, name: "cil-info" },
                              }),
                              _c("strong", [
                                _vm._v(
                                  "Restaurants that have " +
                                    _vm._s(_vm.form.btw_nummer) +
                                    " VAT (BTW) Number:"
                                ),
                              ]),
                              _c(
                                "ul",
                                { staticClass: "pl-3 mt-2 mb-0" },
                                _vm._l(
                                  _vm.allRestaurantByVAT,
                                  function (restaurant) {
                                    return _c(
                                      "li",
                                      { key: restaurant.value },
                                      [
                                        _c(
                                          "CLink",
                                          {
                                            attrs: {
                                              to: {
                                                name: "Edit Restaurant",
                                                params: {
                                                  id: restaurant.value,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(restaurant.label) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-3 d-flex flex-column flex-md-row" },
                        [
                          _c("CInput", {
                            staticClass: "w-100 mr-2",
                            attrs: {
                              label: "Company Name:",
                              placeholder: "Company Name",
                              lazy: false,
                              value: _vm.$v.form.firma_naam.$model,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.$v.form.firma_naam,
                                  "$model",
                                  $event
                                )
                              },
                            },
                          }),
                          _c("CInput", {
                            staticClass: "w-100",
                            attrs: {
                              label: "Company Type:",
                              placeholder: "Company Type",
                              lazy: false,
                              value: _vm.$v.form.firma_type.$model,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.$v.form.firma_type,
                                  "$model",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "section",
                        { staticClass: "address mb-3" },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group w-100" },
                            [
                              _c(
                                "CLink",
                                {
                                  attrs: { color: "info" },
                                  on: { click: _vm.copyFromRestaurantAddress },
                                },
                                [
                                  _vm.copySucceeded.restaurantAddress
                                    ? _c(
                                        "span",
                                        [
                                          _c("CIcon", {
                                            attrs: { name: "cil-check-alt" },
                                          }),
                                          _vm._v(" Copied!"),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "span",
                                        [
                                          _c("CIcon", {
                                            attrs: { name: "cil-copy" },
                                          }),
                                          _vm._v(
                                            " Copy From Restaurant Address"
                                          ),
                                        ],
                                        1
                                      ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("CInput", {
                            staticClass: "w-100",
                            attrs: {
                              id: "verify_firm_address",
                              value: _vm.verified_firm_address,
                              label: "Verify Company Address From Google:",
                              placeholder: "Enter a location",
                              lazy: false,
                            },
                            on: {
                              "update:value": function ($event) {
                                _vm.verified_firm_address = $event
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend",
                                fn: function () {
                                  return [
                                    _c(
                                      "CButton",
                                      { attrs: { color: "info" } },
                                      [
                                        _c("CIcon", {
                                          attrs: { name: "cil-location-pin" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column flex-md-row" },
                            [
                              _c("CTextarea", {
                                staticClass: "w-100",
                                attrs: {
                                  rows: "2",
                                  label: "Address:",
                                  placeholder: "Street name and house number",
                                  lazy: false,
                                  value: _vm.$v.form.firma_mz_adres.$model,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.$v.form.firma_mz_adres,
                                      "$model",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column flex-md-row" },
                            [
                              _c("CInput", {
                                staticClass: "w-100 mr-2",
                                attrs: {
                                  label: "Postal Code:",
                                  placeholder: "Zip or Postal Code",
                                  lazy: false,
                                  value: _vm.$v.form.firma_mz_postcode.$model,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.$v.form.firma_mz_postcode,
                                      "$model",
                                      $event
                                    )
                                  },
                                },
                              }),
                              _c("CInput", {
                                staticClass: "w-100 mr-2",
                                attrs: {
                                  label: "City:",
                                  placeholder: "City",
                                  lazy: false,
                                  value: _vm.$v.form.firma_mz_city.$model,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.$v.form.firma_mz_city,
                                      "$model",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-3 d-flex flex-column flex-md-row" },
                        [
                          _c("CInput", {
                            staticClass: "w-100 mr-md-2",
                            attrs: {
                              label: "Bank Account Holder:",
                              placeholder: "Bank Account Holder",
                              lazy: false,
                              value: _vm.$v.form.bank_rekeninghouder.$model,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.$v.form.bank_rekeninghouder,
                                  "$model",
                                  $event
                                )
                              },
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "w-100 d-flex flex-row" },
                            [
                              _c("CInput", {
                                staticClass: "w-100 mr-2",
                                attrs: {
                                  label: "Bank Name:",
                                  placeholder: "Bank Name",
                                  lazy: false,
                                  value: _vm.$v.form.bank_naam.$model,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.$v.form.bank_naam,
                                      "$model",
                                      $event
                                    )
                                  },
                                },
                              }),
                              _c("CInput", {
                                staticClass: "w-100",
                                attrs: {
                                  label: "Bank Account Number (IBAN):",
                                  placeholder: "Bank Account Number (IBAN)",
                                  lazy: false,
                                  value: _vm.$v.form.bank_rekeningnummer.$model,
                                  isValid: _vm.checkIfValid(
                                    "bank_rekeningnummer"
                                  ),
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.$v.form.bank_rekeningnummer,
                                      "$model",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("h6", { staticClass: "border-bottom pb-1" }, [
                        _vm._v("Contact Person 1:"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "mb-3 d-flex flex-column flex-md-row" },
                        [
                          _c("CInput", {
                            staticClass: "w-100 mr-md-2",
                            attrs: {
                              label: "Name:",
                              placeholder: "Name",
                              lazy: false,
                              value: _vm.$v.form.eigenaar1_naam.$model,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.$v.form.eigenaar1_naam,
                                  "$model",
                                  $event
                                )
                              },
                            },
                          }),
                          _c("div", { staticClass: "w-100 d-flex flex-row" }, [
                            _c(
                              "div",
                              { staticClass: "form-group w-100 mr-2" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "d-block" },
                                  [
                                    _vm._v(" Mobile Phone: "),
                                    _c(
                                      "CLink",
                                      {
                                        directives: [
                                          {
                                            name: "c-tooltip",
                                            rawName: "v-c-tooltip",
                                            value:
                                              "Copy from Restaurant Mobile Number",
                                            expression:
                                              "'Copy from Restaurant Mobile Number'",
                                          },
                                        ],
                                        attrs: { color: "info" },
                                        on: {
                                          click: _vm.copyFromRestaurantGsm,
                                        },
                                      },
                                      [
                                        _vm.copySucceeded.restaurantGsm
                                          ? _c(
                                              "span",
                                              [
                                                _c("CIcon", {
                                                  attrs: {
                                                    name: "cil-check-alt",
                                                  },
                                                }),
                                                _vm._v(" Copied!"),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "span",
                                              [
                                                _c("CIcon", {
                                                  attrs: { name: "cil-copy" },
                                                }),
                                              ],
                                              1
                                            ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("CInput", {
                                  staticClass: "w-100 mr-2",
                                  attrs: {
                                    placeholder: "Mobile Phone",
                                    lazy: false,
                                    value: _vm.$v.form.eigenaar1_gsm.$model,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.eigenaar1_gsm,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "form-group w-100 mr-2" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "d-block" },
                                  [
                                    _vm._v(" Email: "),
                                    _c(
                                      "CLink",
                                      {
                                        directives: [
                                          {
                                            name: "c-tooltip",
                                            rawName: "v-c-tooltip",
                                            value: "Copy from Restaurant Email",
                                            expression:
                                              "'Copy from Restaurant Email'",
                                          },
                                        ],
                                        attrs: { color: "info" },
                                        on: {
                                          click: _vm.copyFromRestaurantEmail,
                                        },
                                      },
                                      [
                                        _vm.copySucceeded.restaurantEmail
                                          ? _c(
                                              "span",
                                              [
                                                _c("CIcon", {
                                                  attrs: {
                                                    name: "cil-check-alt",
                                                  },
                                                }),
                                                _vm._v(" Copied!"),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "span",
                                              [
                                                _c("CIcon", {
                                                  attrs: { name: "cil-copy" },
                                                }),
                                              ],
                                              1
                                            ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("CInput", {
                                  staticClass: "w-100 email",
                                  attrs: {
                                    placeholder: "Email",
                                    lazy: false,
                                    value: _vm.$v.form.eigenaar1_email.$model,
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.eigenaar1_email,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("h6", { staticClass: "border-bottom pb-1" }, [
                        _vm._v("Contact Person 2:"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "mb-3 d-flex flex-column flex-md-row" },
                        [
                          _c("CInput", {
                            staticClass: "w-100 mr-md-2",
                            attrs: {
                              label: "Name:",
                              placeholder: "Name",
                              lazy: false,
                              value: _vm.$v.form.eigenaar2_naam.$model,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.$v.form.eigenaar2_naam,
                                  "$model",
                                  $event
                                )
                              },
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "w-100 d-flex flex-row" },
                            [
                              _c("CInput", {
                                staticClass: "w-100 mr-2",
                                attrs: {
                                  label: "Mobile Phone:",
                                  placeholder: "Mobile Phone",
                                  lazy: false,
                                  value: _vm.$v.form.eigenaar2_gsm.$model,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.$v.form.eigenaar2_gsm,
                                      "$model",
                                      $event
                                    )
                                  },
                                },
                              }),
                              _c("CInput", {
                                staticClass: "w-100 email",
                                attrs: {
                                  label: "Email:",
                                  placeholder: "Email",
                                  lazy: false,
                                  value: _vm.$v.form.eigenaar2_email.$model,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.$v.form.eigenaar2_email,
                                      "$model",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column flex-md-row" },
                        [
                          _c("CTextarea", {
                            staticClass: "w-100",
                            attrs: {
                              rows: "2",
                              label: "References:",
                              placeholder: "References",
                              lazy: false,
                              value: _vm.$v.form.referenties.$model,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.$v.form.referenties,
                                  "$model",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "CTab",
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        [
                          _c("CIcon", { attrs: { name: "cil-settings" } }),
                          _vm._v(" Settings "),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mb-3 d-flex flex-column flex-md-row flex-gap-2 border-bottom",
                        },
                        [
                          _vm.isEdit
                            ? _c("CInput", {
                                staticClass: "w-100",
                                attrs: {
                                  label: "Password",
                                  type: _vm.showPassword ? "text" : "password",
                                  autocomplete: "new-password",
                                  lazy: false,
                                  value: _vm.$v.form.password.$model,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.$v.form.password,
                                      "$model",
                                      $event
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c(
                                            "CButton",
                                            {
                                              staticClass: "small",
                                              attrs: { color: "secondary" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.showPassword =
                                                    !_vm.showPassword
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.showPassword
                                                      ? "HIDE"
                                                      : "SHOW"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3252957158
                                ),
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "w-100" },
                            [
                              _c("CInput", {
                                staticClass: "w-100",
                                attrs: {
                                  disabled: true,
                                  type: "date",
                                  label: "Start Date:",
                                  lazy: false,
                                  value: _vm.$v.form.startdate.$model,
                                  max: new Date().toISOString().slice(0, 10),
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.$v.form.startdate,
                                      "$model",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-3 d-flex flex-column flex-md-row" },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group w-100" },
                            [
                              _c("label", { staticClass: "d-block" }, [
                                _vm._v("Connection Method:"),
                              ]),
                              _c("CInputRadioGroup", {
                                attrs: {
                                  name: "connectiewijze",
                                  options: [
                                    { value: "box", label: "MagicBox" },
                                    {
                                      value: "soft",
                                      label: "Etenonline Software",
                                    },
                                    { value: "mail", label: "E-Mail" },
                                  ],
                                  checked: _vm.$v.form.connectiewijze.$model,
                                  custom: "",
                                  inline: "",
                                },
                                on: {
                                  "update:checked": function ($event) {
                                    return _vm.$set(
                                      _vm.$v.form.connectiewijze,
                                      "$model",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm.$v.form.connectiewijze.$model == "box"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "mb-3 d-flex flex-column flex-md-row flex-gap-2",
                            },
                            [
                              _c("CSelect", {
                                staticClass: "w-100",
                                attrs: {
                                  label: "Box Payment Type:",
                                  placeholder: "Please select..",
                                  lazy: false,
                                  value: _vm.$v.form.boxpaymenttype.$model,
                                  options: [
                                    { value: "rent", label: "Rent" },
                                    { value: "buy", label: "Buy" },
                                  ],
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.$v.form.boxpaymenttype,
                                      "$model",
                                      $event
                                    )
                                  },
                                },
                              }),
                              _c("CInput", {
                                staticClass: "w-100",
                                attrs: {
                                  label: "Serial Number:",
                                  placeholder: "Serial Number",
                                  lazy: false,
                                  value: _vm.$v.form.boxsn.$model,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.$v.form.boxsn,
                                      "$model",
                                      $event
                                    )
                                  },
                                },
                              }),
                              _c("CInput", {
                                staticClass: "w-100",
                                attrs: {
                                  label: "SIM Number:",
                                  placeholder: "SIM Number",
                                  lazy: false,
                                  value: _vm.$v.form.boxsim.$model,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.$v.form.boxsim,
                                      "$model",
                                      $event
                                    )
                                  },
                                },
                              }),
                              _vm.$v.form.boxpaymenttype.$model == "rent"
                                ? _c("CInput", {
                                    staticClass: "w-100",
                                    attrs: {
                                      type: "date",
                                      label: "Box Rental Date:",
                                      lazy: false,
                                      value:
                                        _vm.$v.form.boxrentstartdate.$model,
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          _vm.$v.form.boxrentstartdate,
                                          "$model",
                                          $event
                                        )
                                      },
                                    },
                                  })
                                : _c("div", {
                                    staticClass: "w-100 d-none d-lg-block",
                                  }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mb-3 d-flex flex-column flex-md-row flex-gap-2",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group w-100" },
                            [
                              _c("label", { staticClass: "d-block" }, [
                                _vm._v("Commission Type:"),
                              ]),
                              _c("CInputRadioGroup", {
                                attrs: {
                                  name: "type_commission",
                                  options: ["A", "B", "C", "D", "E"],
                                  checked: _vm.$v.form.type_commission.$model,
                                  custom: "",
                                  inline: "",
                                },
                                on: {
                                  "update:checked": function ($event) {
                                    return _vm.$set(
                                      _vm.$v.form.type_commission,
                                      "$model",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.hasdeliveryService
                            ? _c(
                                "div",
                                { staticClass: "form-group w-100" },
                                [
                                  _c("label", { staticClass: "d-block" }, [
                                    _vm._v(
                                      "🚚 Delivery Service Commission Type:"
                                    ),
                                  ]),
                                  _c("CInputRadioGroup", {
                                    attrs: {
                                      name: "type_commission_ds",
                                      options: ["U", "V", "W", "X"],
                                      checked:
                                        _vm.$v.form.type_commission_ds.$model,
                                      custom: "",
                                      inline: "",
                                    },
                                    on: {
                                      "update:checked": function ($event) {
                                        return _vm.$set(
                                          _vm.$v.form.type_commission_ds,
                                          "$model",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-1 d-flex flex-column flex-md-row" },
                        [
                          _c("CTextarea", {
                            staticClass: "w-100",
                            attrs: {
                              rows: "3",
                              label: "Internal Note:",
                              placeholder: "Internal Note",
                              lazy: false,
                              value: _vm.$v.form.note_internal.$model,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.$v.form.note_internal,
                                  "$model",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "mb-3 d-flex flex-column" }, [
                        _c(
                          "div",
                          { staticClass: "form-group w-100 mr-2" },
                          [
                            _c(
                              "label",
                              { staticClass: "d-block border-bottom" },
                              [_vm._v("Temporarily Closed ?")]
                            ),
                            _c("CInputRadioGroup", {
                              attrs: {
                                name: "temporarily_closed",
                                options: [
                                  { value: null, label: "No" },
                                  { value: "temp_closed", label: "Now Closed" },
                                  {
                                    value: "temp_closed_from",
                                    label: "Closed From ...",
                                  },
                                ],
                                checked: _vm.temporarily_closed,
                                custom: "",
                                inline: "",
                              },
                              on: {
                                "update:checked": function ($event) {
                                  _vm.temporarily_closed = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.temporarily_closed
                          ? _c(
                              "div",
                              {
                                staticClass: "d-inline-flex flex-wrap",
                                staticStyle: { gap: "1rem" },
                              },
                              [
                                _vm.temporarily_closed == "temp_closed_from"
                                  ? _c("CInput", {
                                      staticClass: "border-right pr-3",
                                      attrs: {
                                        type: "date",
                                        label: "From What Date:",
                                        lazy: false,
                                        value:
                                          _vm.$v.form.temp_closed_from_date
                                            .$model,
                                        min: new Date()
                                          .toISOString()
                                          .slice(0, 10),
                                        isValid: _vm.checkIfValid(
                                          "temp_closed_from_date"
                                        ),
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.$v.form.temp_closed_from_date,
                                            "$model",
                                            $event
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "d-block",
                                        staticStyle: { "font-weight": "500" },
                                      },
                                      [_vm._v("Closed Until")]
                                    ),
                                    _c("CSwitch", {
                                      staticClass: "mt-020",
                                      attrs: {
                                        checked:
                                          _vm.$v.form.temp_closed_untill.$model,
                                        labelOn: "YES",
                                        labelOff: "NO",
                                        color: "success",
                                        shape: "pill",
                                        size: "lg",
                                        lazy: false,
                                      },
                                      on: {
                                        "update:checked": [
                                          function ($event) {
                                            return _vm.$set(
                                              _vm.$v.form.temp_closed_untill,
                                              "$model",
                                              $event
                                            )
                                          },
                                          (value) => {
                                            if (!value)
                                              _vm.form.temp_closed_untill_date =
                                                null
                                          },
                                        ],
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.form.temp_closed_untill == true
                                  ? _c("CInput", {
                                      attrs: {
                                        type: "date",
                                        label: "Date:",
                                        lazy: false,
                                        value:
                                          _vm.$v.form.temp_closed_untill_date
                                            .$model,
                                        min: _vm.$v.form.temp_closed_from_date
                                          .$model,
                                        isValid: _vm.checkIfValid(
                                          "temp_closed_untill_date"
                                        ),
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.$v.form.temp_closed_untill_date,
                                            "$model",
                                            $event
                                          )
                                        },
                                        "update:min": function ($event) {
                                          return _vm.$set(
                                            _vm.$v.form.temp_closed_from_date,
                                            "$model",
                                            $event
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "mb-3 d-flex flex-column" }, [
                        _c(
                          "div",
                          { staticClass: "form-group w-100 mr-2" },
                          [
                            _c(
                              "label",
                              { staticClass: "d-block border-bottom" },
                              [_vm._v("Only Pickup ?")]
                            ),
                            _c("CInputRadioGroup", {
                              attrs: {
                                name: "just_takeaway",
                                options: [
                                  { value: null, label: "No" },
                                  {
                                    value: "onlytakeaway",
                                    label: "Pickup Now",
                                  },
                                  {
                                    value: "onlypickup_from",
                                    label: "Pickup From ...",
                                  },
                                ],
                                checked: _vm.just_takeaway,
                                custom: "",
                                inline: "",
                              },
                              on: {
                                "update:checked": function ($event) {
                                  _vm.just_takeaway = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.just_takeaway
                          ? _c(
                              "div",
                              {
                                staticClass: "d-inline-flex flex-wrap",
                                staticStyle: { gap: "1rem" },
                              },
                              [
                                _vm.just_takeaway == "onlypickup_from"
                                  ? _c("CInput", {
                                      staticClass: "border-right pr-3",
                                      attrs: {
                                        type: "date",
                                        label: "From What Date:",
                                        lazy: false,
                                        value:
                                          _vm.$v.form.onlypickup_from_date
                                            .$model,
                                        min: new Date()
                                          .toISOString()
                                          .slice(0, 10),
                                        isValid: _vm.checkIfValid(
                                          "onlypickup_from_date"
                                        ),
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.$v.form.onlypickup_from_date,
                                            "$model",
                                            $event
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "d-block",
                                        staticStyle: { "font-weight": "500" },
                                      },
                                      [_vm._v("Pickup Until")]
                                    ),
                                    _c("CSwitch", {
                                      staticClass: "mt-020",
                                      attrs: {
                                        checked:
                                          _vm.$v.form.onlypickup_untill.$model,
                                        labelOn: "YES",
                                        labelOff: "NO",
                                        color: "success",
                                        shape: "pill",
                                        size: "lg",
                                        lazy: false,
                                      },
                                      on: {
                                        "update:checked": [
                                          function ($event) {
                                            return _vm.$set(
                                              _vm.$v.form.onlypickup_untill,
                                              "$model",
                                              $event
                                            )
                                          },
                                          (value) => {
                                            if (!value)
                                              _vm.form.onlypickup_untill_date =
                                                null
                                          },
                                        ],
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.form.onlypickup_untill == true
                                  ? _c("CInput", {
                                      attrs: {
                                        type: "date",
                                        label: "Date:",
                                        lazy: false,
                                        value:
                                          _vm.$v.form.onlypickup_untill_date
                                            .$model,
                                        min: _vm.$v.form.onlypickup_from_date
                                          .$model,
                                        isValid: _vm.checkIfValid(
                                          "onlypickup_untill_date"
                                        ),
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.$v.form.onlypickup_untill_date,
                                            "$model",
                                            $event
                                          )
                                        },
                                        "update:min": function ($event) {
                                          return _vm.$set(
                                            _vm.$v.form.onlypickup_from_date,
                                            "$model",
                                            $event
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mb-2 d-flex flex-column flex-md-row flex-gap-2",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group w-100" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "d-block",
                                  staticStyle: { "font-weight": "500" },
                                },
                                [_vm._v("Menu Active:")]
                              ),
                              _c("CSwitch", {
                                staticClass: "mt-020",
                                attrs: {
                                  checked: _vm.$v.form.menu_active.$model,
                                  labelOn: "YES",
                                  labelOff: "NO",
                                  color: "success",
                                  shape: "pill",
                                  size: "lg",
                                  lazy: false,
                                },
                                on: {
                                  "update:checked": function ($event) {
                                    return _vm.$set(
                                      _vm.$v.form.menu_active,
                                      "$model",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group w-100" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "d-block",
                                  staticStyle: { "font-weight": "500" },
                                },
                                [_vm._v("Use Takeout Price:")]
                              ),
                              _c("CSwitch", {
                                staticClass: "mt-020",
                                attrs: {
                                  checked: _vm.$v.form.usetoprice.$model,
                                  labelOn: "YES",
                                  labelOff: "NO",
                                  color: "success",
                                  shape: "pill",
                                  size: "lg",
                                  lazy: false,
                                },
                                on: {
                                  "update:checked": function ($event) {
                                    return _vm.$set(
                                      _vm.$v.form.usetoprice,
                                      "$model",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group w-100" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "d-block",
                                  staticStyle: { "font-weight": "500" },
                                },
                                [
                                  _vm._v(
                                    "Relationship Active (Contract Signed):"
                                  ),
                                ]
                              ),
                              _c("CSwitch", {
                                staticClass: "mt-020",
                                attrs: {
                                  disabled: true,
                                  checked: _vm.$v.form.relation_active.$model,
                                  labelOn: "YES",
                                  labelOff: "NO",
                                  color: "success",
                                  shape: "pill",
                                  size: "lg",
                                  lazy: false,
                                },
                                on: {
                                  "update:checked": function ($event) {
                                    return _vm.$set(
                                      _vm.$v.form.relation_active,
                                      "$model",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "form-group w-100" }, [
                            _c(
                              "label",
                              {
                                staticClass: "d-block",
                                staticStyle: { "font-weight": "500" },
                              },
                              [_vm._v("Online Payment Only:")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-column align-items-center",
                              },
                              [
                                _c("CSwitch", {
                                  staticClass: "mt-020 align-self-start",
                                  class: {
                                    "is-invalid": _vm.hasdeliveryService,
                                  },
                                  attrs: {
                                    disabled: _vm.hasdeliveryService,
                                    checked: _vm.$v.form.onlyonlinepay.$model,
                                    labelOn: "YES",
                                    labelOff: "NO",
                                    color: "success",
                                    shape: "pill",
                                    size: "lg",
                                    lazy: false,
                                  },
                                  on: {
                                    "update:checked": function ($event) {
                                      return _vm.$set(
                                        _vm.$v.form.onlyonlinepay,
                                        "$model",
                                        $event
                                      )
                                    },
                                  },
                                }),
                                _c("div", { staticClass: "invalid-feedback" }, [
                                  _vm._v(
                                    " ⚠ Locked: Restaurant offers delivery service "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-3 d-flex flex-column flex-md-row" },
                        [
                          _c("CSelect", {
                            staticClass: "w-100",
                            attrs: {
                              label: "Food Preparation Time:",
                              placeholder: "Please select..",
                              value: _vm.$v.form.food_preparation_time.$model,
                              options: [
                                { value: 15, label: "15" },
                                { value: 30, label: "30" },
                                { value: 45, label: "45" },
                              ],
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.$v.form.food_preparation_time,
                                  "$model",
                                  $event
                                )
                              },
                            },
                          }),
                          _c("div", { staticClass: "w-100" }),
                          _c("div", { staticClass: "w-100" }),
                          _c("div", { staticClass: "w-100" }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "CTab",
                    [
                      _c(
                        "template",
                        { slot: "title" },
                        [
                          _c("CIcon", { attrs: { name: "cil-pen-nib" } }),
                          _vm._v(" Contract "),
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            { attrs: { sm: "12" } },
                            [
                              _c(
                                "CCard",
                                [
                                  _c(
                                    "CCardHeader",
                                    [
                                      _c("CIcon", {
                                        staticClass: "align-top mr-2",
                                        attrs: { name: "cil-pen-nib" },
                                      }),
                                      _c("strong", [_vm._v("Contract")]),
                                      _vm.selectedContractFile
                                        ? _c(
                                            "small",
                                            {
                                              staticClass:
                                                "d-sm-inline d-block",
                                            },
                                            [
                                              _vm._v(" ( "),
                                              _c(
                                                "CLink",
                                                {
                                                  on: {
                                                    click: () =>
                                                      (_vm.selectedContractFile =
                                                        null),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.form
                                                          .selectedContract
                                                      ) +
                                                      " ❌ "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" ) "),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "card-header-actions" },
                                        [
                                          _vm.contractUploadAllowed
                                            ? _c(
                                                "label",
                                                {
                                                  directives: [
                                                    {
                                                      name: "c-tooltip",
                                                      rawName: "v-c-tooltip",
                                                      value:
                                                        "Upload Signed Contract",
                                                      expression:
                                                        "'Upload Signed Contract'",
                                                    },
                                                  ],
                                                  staticClass: "text-info mb-0",
                                                  attrs: {
                                                    for: "contract",
                                                    role: "button",
                                                  },
                                                },
                                                [
                                                  _c("CIcon", {
                                                    attrs: {
                                                      name: "cil-cloud-upload",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.form.upload_contract
                                            ? _c(
                                                "CLink",
                                                {
                                                  directives: [
                                                    {
                                                      name: "c-tooltip",
                                                      rawName: "v-c-tooltip",
                                                      value:
                                                        "Download Existing Contract",
                                                      expression:
                                                        "'Download Existing Contract'",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "text-success card-header-action",
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.download(
                                                        "contract"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("CIcon", {
                                                    attrs: {
                                                      name: "cil-cloud-download",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("CCardBody", [
                                    _c(
                                      "div",
                                      [
                                        _c("CInput", {
                                          staticClass: "mb-0",
                                          attrs: {
                                            value:
                                              _vm.$v.form.selectedContract
                                                .$model,
                                            type: "hidden",
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.$v.form.selectedContract,
                                                "$model",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _c("CInputFile", {
                                          class: !_vm.checkIfValid(
                                            "selectedContract"
                                          )
                                            ? "is-invalid"
                                            : "",
                                          staticStyle: { display: "none" },
                                          attrs: { id: "contract" },
                                          on: {
                                            change:
                                              _vm.onSelectedContractChanged,
                                          },
                                        }),
                                        !_vm.checkIfValid("selectedContract")
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "invalid-feedback",
                                              },
                                              [
                                                !_vm.$v.form.selectedContract
                                                  .required
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "error border-bottom",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Please select a valid file! (*.pdf, *.jpeg, *.jpg, *.png, *.gif) "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm.contractGenerateAllowed
                                      ? _c("section", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mb-3 d-flex flex-column flex-md-row",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group w-100",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    { staticClass: "d-block" },
                                                    [
                                                      _vm._v(
                                                        "Contract Language:"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "contract-lang-group",
                                                    },
                                                    _vm._l(
                                                      _vm.allLanguages,
                                                      function (lang) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: lang.value,
                                                            staticClass:
                                                              "form-check form-check-inline",
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .formContract
                                                                      .contract_lang,
                                                                  expression:
                                                                    "formContract.contract_lang",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-check-input d-none",
                                                              attrs: {
                                                                type: "radio",
                                                                name: "lang",
                                                                id: `lang_${lang.value}`,
                                                              },
                                                              domProps: {
                                                                value:
                                                                  lang.value,
                                                                checked: _vm._q(
                                                                  _vm
                                                                    .formContract
                                                                    .contract_lang,
                                                                  lang.value
                                                                ),
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm.formContract,
                                                                      "contract_lang",
                                                                      lang.value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "form-check-label",
                                                                attrs: {
                                                                  for: `lang_${lang.value}`,
                                                                },
                                                              },
                                                              [
                                                                lang.value ==
                                                                "en"
                                                                  ? _c(
                                                                      "CIcon",
                                                                      {
                                                                        staticClass:
                                                                          "flag mr-1",
                                                                        attrs: {
                                                                          width: 21,
                                                                          height: 16,
                                                                          content:
                                                                            _vm
                                                                              .$options
                                                                              .flagSet[
                                                                              "cifGb"
                                                                            ],
                                                                        },
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      "CIcon",
                                                                      {
                                                                        staticClass:
                                                                          "flag mr-1",
                                                                        attrs: {
                                                                          height: 14,
                                                                          content:
                                                                            _vm
                                                                              .$options
                                                                              .flagSet[
                                                                              "cif" +
                                                                                lang.value
                                                                                  .charAt(
                                                                                    0
                                                                                  )
                                                                                  .toUpperCase() +
                                                                                lang.value.slice(
                                                                                  1
                                                                                )
                                                                            ],
                                                                        },
                                                                      }
                                                                    ),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      lang.label
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mb-3 d-flex flex-column flex-md-row",
                                            },
                                            [
                                              _c("CInput", {
                                                staticClass: "w-100 mr-2",
                                                attrs: {
                                                  label: "Name:",
                                                  placeholder: "Name",
                                                  lazy: false,
                                                  value: _vm.formContract.name,
                                                },
                                                on: {
                                                  "update:value": function (
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.formContract,
                                                      "name",
                                                      $event
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("CInput", {
                                                staticClass: "w-100",
                                                attrs: {
                                                  label: "Place:",
                                                  placeholder: "Place",
                                                  lazy: false,
                                                  value: _vm.formContract.place,
                                                },
                                                on: {
                                                  "update:value": function (
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.formContract,
                                                      "place",
                                                      $event
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column flex-md-row",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group w-100",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    { staticClass: "d-block" },
                                                    [_vm._v("Signature:")]
                                                  ),
                                                  _c("VueSignaturePad", {
                                                    ref: "signaturePad",
                                                    attrs: {
                                                      id: "signature",
                                                      width: "100%",
                                                      height: "200px",
                                                      options: {
                                                        penColor: "#000",
                                                        onBegin: () => {
                                                          _vm.$refs.signaturePad.resizeCanvas()
                                                        },
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass: "small",
                                                      attrs: { role: "button" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.$refs.signaturePad.undoSignature()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("CIcon", {
                                                        attrs: {
                                                          name: "cil-action-undo",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        " Undo the Signature "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("div", {
                                                staticClass: "w-100",
                                              }),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("RestaurantContract", {
                        ref: "contract",
                        attrs: {
                          form: _vm.form,
                          formContract: _vm.formContract,
                          sign: _vm.formContract.sign,
                        },
                        on: { generated: _vm.handleContractGenerationComplete },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.mapModal
            ? _c(
                "CModal",
                {
                  attrs: {
                    show: _vm.mapModal,
                    closeOnBackdrop: true,
                    centered: false,
                    size: "xl",
                    color: "dark",
                    title: "Restaurant Delivery Areas",
                  },
                  on: {
                    "update:show": function ($event) {
                      _vm.mapModal = $event
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "footer",
                        fn: function () {
                          return [
                            _c(
                              "CButton",
                              {
                                attrs: { color: "dark" },
                                on: {
                                  click: function ($event) {
                                    _vm.mapModal = false
                                  },
                                },
                              },
                              [_vm._v("Close")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1744024222
                  ),
                },
                [
                  _c("map-restaurant-delivery-areas", {
                    attrs: {
                      zip_codes: _vm.form.zip_codes,
                      restaurant_name: _vm.form.restaurant_name,
                      restaurant_lat: _vm.form.resto_lat,
                      restaurant_lng: _vm.form.resto_lng,
                    },
                    on: {
                      "update:zip_codes": function ($event) {
                        return _vm.$set(_vm.form, "zip_codes", $event)
                      },
                      "update:restaurant_name": function ($event) {
                        return _vm.$set(_vm.form, "restaurant_name", $event)
                      },
                      "update:restaurant_lat": function ($event) {
                        return _vm.$set(_vm.form, "resto_lat", $event)
                      },
                      "update:restaurant_lng": function ($event) {
                        return _vm.$set(_vm.form, "resto_lng", $event)
                      },
                      "add:zip": (zip) =>
                        _vm.$refs.resZipCodes.addZipCodes(zip),
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("CCardFooter", { staticClass: "sticky" }, [
        _c(
          "div",
          {
            staticClass: "d-flex flex-wrap align-items-center",
            staticStyle: { gap: "0.75rem" },
          },
          [
            _c(
              "CButton",
              {
                attrs: { color: "primary", disabled: _vm.submitted },
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [_vm._v(" Submit ")]
            ),
            _c("div", { staticClass: "ml-auto" }),
          ],
          1
        ),
      ]),
      _c("mc-spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.submitted,
            expression: "submitted",
          },
        ],
        attrs: { opacity: 0.8 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }